<template>
    <div>
		<v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card>
			<v-toolbar flat color="white">
				<v-btn 
					small
					color = 'primary'
					@click=exportReport
				> 
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					label="Buscar cualquier columna"
					class='mt-5'
					style='flex:5'
				></v-text-field>
				<v-spacer ></v-spacer>
				<v-text-field
					v-model="days"
					label="Días de caducidad"
					class='mt-5'
					append-icon="mdi-magnify"
					@click:append="getProducts"
				></v-text-field>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="dataTable"
				:items-per-page=itemsPerPage
				:search=search
				:loading=loadingTable
				class="elevation-1"
				item-key="inventory_item_id"
				:key="`list-purchase-invoices-${tenantId}`"
    			show-select
				v-model=selected
				:options.sync="options"
			>
				<template v-slot:item.invoice_number="{item}">
					<a target="_blank" :href="`factura-compra/${item.invoice_id}`">{{item.invoice_number}}</a>
				</template>
			</v-data-table>
		</v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    name : 'ListExpiredAndExpiringInvetoryComponent',
    data : ()=> ({
        headers: [
			{ text: 'Cod Inventario', value: 'inventory_item_id',width:'80px' },			
			{ text: 'Producto', value: 'product_name',width:'120px'},
			{ text: 'Factura', value: 'invoice_number' },
            { text: 'Expiración', value: 'expiration_date' },
			{ text: 'Días', value: 'dias' },
            { text: 'cantidad', value: 'available_to_promise_total' },
            { text: 'Lote', value: 'lot_id' },
            { text: 'Bodega', value: 'bodega' },
            { text: 'Proveedor', value: 'group_name',width:'120px' },
        ],
        search:null,
		overlay:false,
        itemsPerPage:15,
		days:120,
        dataTable:[],
		selected: [],
		options:{}
    }),
    computed:{
    
        ...mapState('master',['loadingTable','tenantId'])
    },
	watch:{
        options: {
            handler () {
                this.getProducts()
            },
            deep: true,
        },
    },
    methods: {

    	...mapMutations('master',['setTitleToolbar','setLoadingTable','setUrl']),

        ...mapActions('master',['requestApi']),

        getProducts(){

            this.setLoadingTable(true)
           
            this.dataTable=[]
            
            this.setUrl('lista-inventario-caducado-por-caducar')
            this.requestApi({
				method: 'GET',
				data :{
					days : this.days
                }
			}).then(res =>{
                //console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_inventario_caducado_por_caducar
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },

		exportReport(){

			this.overlay= true
			this.setUrl('lista-inventario-caducado-por-caducar')
            this.requestApi({
				method: 'POST',
				data :{
					days : this.days,
					selected : this.selected.map( obj => { 
									return obj.inventory_item_id
								})
                }
			}).then(res =>{
				
				console.log(res)
                let link = document.createElement("a");
                link.href = res.data.file;
                link.download = "Reporte invnetario por caducar.xlsx";
                link.click();

			}).catch(()=>{

			}).then(()=>{
				this.overlay= false
            })

		}

    },
    mounted(){
        this.setTitleToolbar('INVENTARIO CADUCADO Y POR CADUCAR')
    }
}
</script>